import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SingleVideoCard from "./SingleVideoCard";

const LiveCardList = (props) => {
  return (
    <>
      {!props.liveVideo.loading ? (
         props.liveVideo.data.live_videos &&
        props.liveVideo.data.live_videos.length > 0 && (
          <>
               
            <div className="new-card-title pt-8">
              <h4>{props.title}</h4>
              {/* <a href="#" onClick={e => {
              e.preventDefault();
              props.setSelectedCategory({ live: "true" })
            }}> */}
              <Link to="/?live=true">
                Show All
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
                  </svg>
                </span>
              </Link>
            </div>
            <div className="new-category-list-card">
              {props.liveVideo.data.live_videos.map((live, i) => (
                <SingleVideoCard live={live} key={i} />
              ))}
            </div>
          </>
        )
      ) : (
        <>
          <div className="new-card-title pt-8">
            <h4>
              <Skeleton width={300} height={40} />
            </h4>
          </div>
          <div className="new-category-list-card">
            {[...Array(5)].map((e, i) => (
              <a key={i}>
                <div className="new-category-card">
                  <div className="new-card-image">
                    <div className="thumbnail-card">
                      <Skeleton className="card-image-loader" />
                    </div>
                    <div className="category-profile-content">
                      <Skeleton className="card-user-image-loader" />
                      <h5>
                        <Skeleton />
                      </h5>
                    </div>
                    <h4>
                      <Skeleton height={30} />
                    </h4>
                  </div>
                  <div className="category-card-link">
                    <Skeleton />
                  </div>
                </div>
              </a>
            ))}
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(null, mapDispatchToProps)(LiveCardList);
