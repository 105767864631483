import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import "./Ecom.css";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

const EcomPaymentIndex = (props) => {

    return (
        <>
            <div className="ecom-payment-sec">
                <Container>
                    <h2>{t("ecom_payment")}</h2>
                    <Form>
                        <Row>
                            <Col md={7} className="resp-mrg-btn-xs">
                                <div className="border-right-divider">
                                    <div className="ecom-payment-header">
                                        <h4>{t("contact_infromation")}</h4>
                                        <p>{t("already_have_an_account")} <Link to="/">{t("login")}</Link></p>
                                    </div>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Enter email" />
                                    </Form.Group>
                                    <Form.Check
                                        type="checkbox"
                                        id="customControlAutosizing"
                                        label="Keep me up to date on news and offers"
                                        custom
                                    />
                                    <div className="shipping-address-sec">
                                        <h4>{t("shipping_address")}</h4>
                                        <Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Control placeholder="First name (optional)" />
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Last name" />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control placeholder="Address" />        
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control placeholder="Apartment, suite, etc. (optional)" />        
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control placeholder="City" />        
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group>    
                                                    <Form.Control
                                                        as="select"
                                                        className="mr-sm-2"
                                                        id="inlineFormCustomSelect"
                                                        custom
                                                    >
                                                        <option value="0">{t("india")}</option>
                                                        <option value="1">{t("USA")}</option>
                                                        <option value="2">{t("UAE")}</option>
                                                        <option value="3">{t("Spin")}</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>    
                                                    <Form.Control
                                                        as="select"
                                                        className="mr-sm-2"
                                                        id="inlineFormCustomSelect"
                                                        custom
                                                    >
                                                    <option value="0">{t("tamil_nadu")}</option>
                                                        <option value="1">{t("kerala")}</option>
                                                        <option value="2">{t("andhar")}</option>
                                                        <option value="3">{t("telegana")}</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Control placeholder="PIN code" />
                                            </Col>
                                        </Row>
                                        <div className="padding-small">
                                            <Form.Check
                                            type="checkbox"
                                            id="customControlAutosizing-1"
                                            label="Save this information for next time"
                                            custom
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                            </Col>
                            <Col md={5}>
                                <div className="ecom-payment-product-details-sec">
                                    <div className="product-details-header-sec">
                                        <div className="product-details-header-card">
                                            <div className="product-details-info">
                                                <Image
                                                    className="product-thumbnail-img"
                                                    src={
                                                        window.location.origin + "/assets/images/ecom/ecom-1.jpg"
                                                    }
                                                />
                                                <h6>{t("light_brown_shoes")}</h6>
                                            </div>
                                            <div className="ecom-payment-product-amount">
                                                <p>$49.00</p>
                                            </div>
                                        </div>
                                        <div className="product-details-header-card">
                                            <div className="product-details-info">
                                                <Image
                                                    className="product-thumbnail-img"
                                                    src={
                                                        window.location.origin + "/assets/images/ecom/ecom-1.jpg"
                                                    }
                                                />
                                                <h6>{t("light_brown_shoes")}</h6>
                                            </div>
                                            <div className="ecom-payment-product-amount">
                                                <p>$49.00</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-details-body-sec">
                                        <div className="product-details-card">
                                            <h5>{t("subtotal")}</h5>
                                            <p className="product-amount">$49.00</p>
                                        </div>
                                        <div className="product-details-card">
                                            <h5>{t("shipping")}</h5>
                                            <p>{t("calculated_at_next_step")}</p>
                                        </div>
                                    </div>
                                    <div className="product-details-footer-sec">
                                        <h5>Total</h5>
                                        <div className="product-details-final-amount">
                                            <span>{t("USD")}</span>
                                            <p>$49.00</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </>
    );
};

export default (translate(EcomPaymentIndex));
