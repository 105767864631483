import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./AddDeliveryAddress.css";
import { saveDeliveryAddressStart } from "../../../store/actions/DeliveryAddressAction";
import { translate, t } from "react-multi-lang";
import { Link } from "react-router-dom";

const AddDeliveryAddress = (props) => {
  const [inputData, setInputData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(saveDeliveryAddressStart(inputData));
  };

  return (
    <div className="card-list-sec">
      <Container>
        <Link
          className="bookmarkes-list notify-title back-button head-title"
          onClick={() =>
            props.location.state && props.location.state.prevPath
              ? props.history.goBack()
              : props.history.push("/")
          }
        >
          <img
            src={window.location.origin + "/assets/images/icons/back.svg"}
            className="svg-clone"
          />
          {t("add_delivery_address")}
        </Link>
        {/* <h4 className="head-title">{t("add_bank")}</h4> */}
        <Row>
          <Col sm={12} md={12}>
            <div className="add-bank-box">
              <Form onSubmit={handleSubmit}>
                <Col md={6}>
                  <Form.Group controlId="formHorizontalNickname">
                    <Form.Label>{t("name")}: (*)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("name")}
                      value={inputData.name}
                      name="route_number"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          name: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="formHorizontalAccountNumber">
                    <Form.Label>{t("address")}: (*)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("address")}
                      value={inputData.address}
                      name="address"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          address: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="formHorizontalFirstname">
                    <Form.Label>{t("landmark")}: (*)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("landmark")}
                      value={inputData.landmark}
                      name="landmark"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          landmark: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formHorizontalFirstname">
                    <Form.Label>{t("state")}: (*)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("state")}
                      value={inputData.state}
                      name="state"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          state: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formHorizontalLastname">
                    <Form.Label>{t("pincode")}: (*)</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder={t("pincode")}
                      value={inputData.pincode}
                      name="pincode"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          pincode: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="formHorizontalBusinessname">
                    <Form.Label>
                      {t("mobile")}: ({t("optional")})
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder={t("contact_number")}
                      value={inputData.contact_number}
                      name="contact_number"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          contact_number: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>

                <div className="edit-save">
                  <Button
                    className="btn gradient-btn gradientcolor addBank"
                    type="submit"
                    disabled={props.deliveryAddressSave.buttonDisable}
                  >
                    {t("submit")}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  deliveryAddressSave: state.deliveryAddress.deliveryAddressSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AddDeliveryAddress));
