import React, { useState, useEffect } from "react";
import {Form,Button,Image,Modal,Tab,Nav,Row,Col} from "react-bootstrap";
import { connect } from "react-redux";
import {
    createOrderByStripeStart,
    createOrderByWalletStart,
} from "../../store/actions/OrderAction";
import { fetchCardDetailsStart } from "../../store/actions/CardsAction";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

const LivePaymentModal = (props) => {
    const [amount, setAmount] = useState(0);
    const [paymentType, setPaymentType] = useState(
        localStorage.getItem("default_payment_method")
    );

    useEffect(() => {
        if (props.paymentModal === true) {
            props.dispatch(fetchCardDetailsStart());
            props.dispatch(fetchWalletDetailsStart());
        }
    }, [props.paymentModal]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (paymentType === "CARD")
            props.dispatch(
                createOrderByStripeStart({
                    user_product_id: props.product.user_product_id,
                    live_video_id: props.liveVideo.live_video_id,
                })
            );
        if (paymentType === "WALLET")
            props.dispatch(
                createOrderByWalletStart({
                    user_product_id: props.product.user_product_id,
                    live_video_id: props.liveVideo.live_video_id,
                })
            );
        props.closePaymentModal();
    };

    return (
        <>
        <Modal
            show={props.paymentModal}
            onHide={props.closePaymentModal}
            centered
            size="lg"
        >
        {props.paymentModal === true ? (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Buy Now</Modal.Title>
                </Modal.Header>
                <Modal.Body className="subscription-tip-ppv-tab">
                    <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey={paymentType}
                    >
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        {configuration.get("configData.is_stripe_enabled") == 1 &&
                                        configuration.get("configData.stripe_publishable_key") !==
                                            "" &&
                                        configuration.get("configData.stripe_secret_key") !==
                                            "" ? (
                                            <Nav.Item>
                                                <Nav.Link
                                                    onClick={() => setPaymentType("CARD")}
                                                    eventKey="CARD"
                                                >
                                                    {t("card_stripe")}
                                                </Nav.Link>
                                            </Nav.Item>
                                        ) : null}
                                    </Nav.Item>
                                    {configuration.get(
                                        "configData.is_wallet_payment_enabled"
                                    ) == 1 ? (
                                        <Nav.Item>
                                            <Nav.Link
                                                onClick={() => setPaymentType("WALLET")}
                                                eventKey="WALLET"
                                            >
                                                {t("wallet")}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ) : null}
                                </Nav>
                            </Col>
                            <Col sm={9}>
                            <div className="card-stripe-box">
                                <Form>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder={t("pay_amount")}
                                            value={props.product.price_formatted}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Tab.Content>
                                        {configuration.get("configData.is_stripe_enabled") ==
                                            1 &&
                                        configuration.get(
                                            "configData.stripe_publishable_key"
                                        ) !== "" &&
                                        configuration.get("configData.stripe_secret_key") !==
                                            "" ? (
                                            <Tab.Pane eventKey="CARD">
                                            <div className="card-stripe-sec">
                                                {props.cards.loading ? (
                                                ""
                                                ) : props.cards.data.cards.length > 0 ? (
                                                props.cards.data.cards.map(
                                                    (card) =>
                                                    card.is_default == 1 && (
                                                        <div className="card-stripe-list-box">
                                                            <h5 className="mb-3">
                                                                XXXX XXXX XXXX {card.last_four}
                                                            </h5>
                                                            <h5 className="text-muted">
                                                                {card.card_type}
                                                            </h5>
                                                            <div className="card-stripe-bottom">
                                                                <div className="card-stripe-action-btn">
                                                                    <p className="card-link-text text-success">
                                                                        {t("default_card")}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                                ) : (
                                                    <div className="card-stripe-item" >
                                                        <Link to="cards">
                                                        <div className="add-account-item">
                                                            <Image
                                                            className="add-account-icon"
                                                            src={
                                                                window.location.origin +
                                                                "/assets/images/icons/new/add-card.svg"
                                                            }
                                                            />
                                                            <h5 className="text-muted">{t("add_card")}</h5>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                            </Tab.Pane>
                                        ) : null}

                                        {configuration.get(
                                            "configData.is_wallet_payment_enabled"
                                        ) == 1 ? (
                                            <Tab.Pane eventKey="WALLET">
                                                {props.wallet.loading ? (
                                                    ""
                                                ) : (
                                                    <div className="card-stripe-box">
                                                        <div className="wallet-balence-amount">
                                                            <h4>{t("available")}</h4>
                                                            <p>
                                                            {
                                                                props.wallet.data.user_wallet
                                                                .remaining_formatted
                                                            }
                                                            </p>
                                                        </div>
                                                        {props.amount > props.wallet.data.user_wallet.remaining ? (
                                                            <div className="">
                                                                <p className="conv-desc desc">
                                                                    {t(
                                                                    "low_wallet_balance_tips_payment_para"
                                                                    )}
                                                                </p>
                                                                <div className="d-flex">
                                                                    <Link
                                                                    to="/wallet"
                                                                    className="withdraw-money-btn"
                                                                    >
                                                                        {t("add_wallet_amount")}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </Tab.Pane>
                                        ) : null}
                                    </Tab.Content>
                                </Form>
                            </div>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        onClick={props.closePaymentModal}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-success"
                        data-dismiss="modal"
                        onClick={handleSubmit}
                        disabled={props.livePayStripe.buttonDisable}
                    >
                        {props.livePayStripe.loadingButtonContent !== null
                        ? props.livePayStripe.loadingButtonContent
                        : t("pay_now")}
                    </Button>
                </Modal.Footer>
            </>
        ) : null}
        </Modal>
        </>
    );
};

const mapStateToPros = (state) => ({
  livePayStripe: state.order.createOrderStripe,
  wallet: state.wallet.walletData,
  cards: state.cards.cardDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LivePaymentModal));
