import React, { useState } from "react";
import { Container, Breadcrumb, Row, Col, Table, Image, InputGroup, FormControl, Button, Form } from "react-bootstrap";
import "./Ecom.css";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

const EcomCartIndex = (props) => {

    // Set the initial count state to zero, 0
    const [count, setCount] = useState(0);

    // Create handleIncrement event handler
    const handleIncrement = () => {
        setCount(prevCount => prevCount + 1);
    };

    //Create handleDecrement event handler
    const handleDecrement = () => {
        setCount(prevCount => prevCount - 1);
    };

    return (
        <>
            <div className="ecom-cart-sec">
                <Container>
                    <Breadcrumb className="ecom-cart-header">
                        <Breadcrumb.Item active>{t("shopping_cart")}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                           {t("proceed_to_checkout")}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{t("order_completed")}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col md={8}>
                            <div className="ecom-cart-table">
                                <Table size="sm">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t("PRODUCT")}</th>
                                            <th>{("PRICE")}</th>
                                            <th>{("QUANTITY")}</th>
                                            <th>{("SUBTOTAL")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Image
                                                    className="ecom-cart-table-img"
                                                    src={
                                                        window.location.origin + "/assets/images/ecom/ecom-1.jpg"
                                                    }
                                                />
                                            </td>
                                            <td>{t("light_brown_shoes")}</td>
                                            <td>$49.00</td>
                                            <td>
                                                <div className="ecom-cart-inc-dec">
                                                    <Form>
                                                        <InputGroup>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text onClick={handleDecrement}><i className="fas fa-minus"></i></InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <FormControl placeholder={count}></FormControl>
                                                            <InputGroup.Append>
                                                                <InputGroup.Text onClick={handleIncrement}><i className="fas fa-plus"></i></InputGroup.Text>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </Form>
                                                </div>
                                            </td>
                                            <td>$49.00</td>
                                        </tr>
                                        <tr >
                                            <td colspan="10">
                                                <div className="ecom-cart-action-btn-sec">
                                                    <Link to="ecom" className="btn-button">
                                                        {t("continue_shopping")}
                                                    </Link>
                                                    {/* <Button type="submit" className="btn-button btn-update tiny">
                                                        Update Shopping Cart
                                                    </Button> */}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Row>
                                    <Col md={12}>
                                        <Form>
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>{t("special_instructions_for_seller")}</Form.Label>
                                                <Form.Control as="textarea" rows={5} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="cart-summary-sec">
                                <h4>{t("CART_TOTALS")}</h4>
                                <div className="cart-sub-total">
                                    <h5>{t("subtotal")}</h5>
                                    <p>$49.00</p>
                                </div>
                                <div className="estimate-shipping-tax-sec">
                                    <h5>{t("estimate_shipping_and_tax")}</h5>
                                    <p>{t("enter_your_destination_to_get_a_shipping_estimate")}</p>
                                    <Form className="check-out-form">
                                        <Form.Group>    
                                            <Form.Label className="mr-sm-2">
                                                {t("country")} <span>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="mr-sm-2"
                                                id="inlineFormCustomSelect"
                                                custom
                                            >
                                                <option value="0">{t("india")}</option>
                                                <option value="1">{t("USA")}</option>
                                                <option value="2">{t("UAE")}</option>
                                                <option value="3">{t("Spin")}</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className="mr-sm-2">
                                                {t("state")}<span>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="mr-sm-2"
                                                id="inlineFormCustomSelect"
                                                custom
                                            >
                                                <option value="0">{t("tamil_nadu")}</option>
                                                <option value="1">{t("kerala")}</option>
                                                <option value="2">{t("andhar")}</option>
                                                <option value="3">{t("telegana")}</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className="mr-sm-2">
                                               {t("postal_code")}
                                            </Form.Label>
                                            <Form.Control size="lg" type="text" placeholder="" />
                                        </Form.Group>
                                        {/* <Button type="submit" className="btn-button">
                                            GET A QUOTE
                                        </Button> */}
                                    </Form>
                                    <div className="ecom-cart-total-amount">
                                        <h5>{t("total")}</h5>
                                        <h3>$49.00</h3>
                                    </div>
                                    <div className="ecom-cart-checkout-btn-sec">
                                        <Button type="submit" className="check-out-btn" href="/ecom-payment">
                                            {t("PROCEED_TO_CHECKOUT")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default (translate(EcomCartIndex));
