import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SideMenu from "./SideMenu";
import HomeContent from "./HomeContent";
import NewCategoryCard from "./NewCategoryCard";
import { fetchCategoriesListStart } from "../../store/actions/LookUpAction";
import LiveCard from "./LiveCard";
import { useLocation } from 'react-router-dom';
import { translate, t } from "react-multi-lang";


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const HomePage = (props) => {
  const [selectedCategory, setSelectedCategory] = useState({
    categoryId: null,
    subCategoryId: null,
  });

  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(!isActive);
  };

  let query = useQuery();

  useEffect(() => {
    let newSelectedCategory = {
      categoryId: null,
      subCategoryId: null,
    };
    newSelectedCategory = query.get("category") ? { ...newSelectedCategory, categoryId: query.get("category") } : newSelectedCategory;
    newSelectedCategory = query.get("subCategory") ? { ...newSelectedCategory, subCategoryId: query.get("subCategory") } : newSelectedCategory;
    newSelectedCategory = query.get("live") ? { ...newSelectedCategory, live: query.get("live") } : newSelectedCategory;
    setSelectedCategory(newSelectedCategory);
  }, [query]);

  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
  }, [])

  return (
    <div>
      <div className="new-category-sec">
        <SideMenu
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          isActive={isActive}
          setActive={setActive}
        />
        <div className="category-right-sec">
          <div className="mobile-nav-filter-sec">
            <a href="#" onClick={toggleClass}>
             {t("categories")}
            </a>
          </div>
          {selectedCategory.live !== null && selectedCategory.live === "true" ?
            <LiveCard />
            : <>
              {selectedCategory.categoryId === null && selectedCategory.subCategoryId === null ?
                <HomeContent
                  setSelectedCategory={setSelectedCategory}
                />
                : <NewCategoryCard
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              }
            </>
          }
        </div>
      </div>
    </div >
  );
};

const mapStateToPros = (state) => ({
  categoriesList: state.lookUp.categoriesList,
  ongoingLiveVideos: state.home.ongoingLiveVideos,
  followingCategories: state.home.followingCategories,
  followingCategoriesLiveVideos: state.home.followingCategoriesLiveVideos,
  exploreCategoriesLiveVideos: state.home.exploreCategoriesLiveVideos,
  recentCategories: state.home.recentCategories,
});
const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(mapStateToPros, mapDispatchToProps)(translate(HomePage));
