import {
  SEARCH_USER_START,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  FETCH_LISTS_DETAILS_START,
  FETCH_LISTS_DETAILS_SUCCESS,
  FETCH_LISTS_DETAILS_FAILURE,
  FETCH_TRENDING_USERS_START,
  FETCH_TRENDING_USERS_SUCCESS,
  FETCH_TRENDING_USERS_FAILURE,
  FETCH_ONGOING_LIVE_VIDEOS_START,
  FETCH_ONGOING_LIVE_VIDEOS_SUCCESS,
  FETCH_ONGOING_LIVE_VIDEOS_FAILURE,
  FETCH_FOLLOWING_CATEGORIES_START,
  FETCH_FOLLOWING_CATEGORIES_SUCCESS,
  FETCH_FOLLOWING_CATEGORIES_FAILURE,
  FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_SUCCESS,
  FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_START,
  FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_FAILURE,
  FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_START,
  FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_SUCCESS,
  FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_FAILURE,
  FETCH_RECENT_CATEGORIES_START,
  FETCH_RECENT_CATEGORIES_SUCCESS,
  FETCH_RECENT_CATEGORIES_FAILURE,
  FETCH_CATEGORY_VIDEOS_START,
  FETCH_CATEGORY_VIDEOS_SUCCESS,
  FETCH_CATEGORY_VIDEOS_FAILURE,
  FETCH_SUB_CATEGORY_VIDEOS_START,
  FETCH_SUB_CATEGORY_VIDEOS_SUCCESS,
  FETCH_SUB_CATEGORY_VIDEOS_FAILURE,
  LIVE_VIDEO_BOOKMARK_SAVE_START,
  LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS,
  LIVE_VIDEO_BOOKMARK_SAVE_FAILURE,
  RESET_ALL_VIDEO_LIST,
  FETCH_BOOKMARKED_VIDEOS_START,
  FETCH_BOOKMARKED_VIDEOS_SUCCESS,
  FETCH_BOOKMARKED_VIDEOS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  searchUser: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  postSug: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  lists: {
    data: {},
    loading: true,
    error: false,
  },
  trendingUsers: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  ongoingLiveVideos: {
    data: {
      live_videos: []
    },
    loading: true,
    error: false
  },
  followingCategories: {
    data: {},
    loading: true,
    error: false,
  },
  followingCategoriesLiveVideos: {
    data: {
      category_live_videos: [],
    },
    loading: true,
    error: false,
  },
  exploreCategoriesLiveVideos: {
    data: {
      category_live_videos: [],
    },
    loading: true,
    error: false,
  },
  recentCategories: {
    data: {},
    loading: true,
    error: false,
  },
  categoryVideos: {
    data: {
      featured_videos: [],
      live_videos: [],
    },
    loading: true,
    error: false,
  },
  subCategoryVideos: {
    data: {
      featured_videos: [],
      live_videos: [],
    },
    loading: true,
    error: false,
  },
  liveBookmarkSave: {
    data: {},
    loading: true,
    error: false,
  },
  bookmarkedVideos: {
    data: {
      live_video_bookmarks: []
    },
    loading: true,
    error: false,
  },
};

const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_USER_START:
      return {
        ...state,
        searchUser: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading...",
          buttonDisable: true,
        },
      };
    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        searchUser: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SEARCH_USER_FAILURE:
      return {
        ...state,
        searchUser: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_LISTS_DETAILS_START:
      return {
        ...state,
        lists: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_LISTS_DETAILS_SUCCESS:
      return {
        ...state,
        lists: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_LISTS_DETAILS_FAILURE:
      return {
        ...state,
        lists: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_TRENDING_USERS_START:
      return {
        ...state,
        trendingUsers: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case FETCH_TRENDING_USERS_SUCCESS:
      return {
        ...state,
        trendingUsers: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_TRENDING_USERS_FAILURE:
      return {
        ...state,
        trendingUsers: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_ONGOING_LIVE_VIDEOS_START:
      return {
        ...state,
        ongoingLiveVideos: {
          data: {
            live_videos: action.data?.fetchMore === true ? state.ongoingLiveVideos.data.live_videos : []
          },
          loading: true,
          error: false,
        }
      };
    case FETCH_ONGOING_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        ongoingLiveVideos: {
          data: {
            ...action.data,
            live_videos: [...state.ongoingLiveVideos.data.live_videos, ...action.data.live_videos],
          },
          loading: false,
          error: false,
        }
      };
    case FETCH_ONGOING_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        ongoingLiveVideos: {
          data: state.ongoingLiveVideos.data,
          loading: true,
          error: action.error,
        }
      };
    case FETCH_FOLLOWING_CATEGORIES_START:
      return {
        ...state,
        followingCategories: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case FETCH_FOLLOWING_CATEGORIES_SUCCESS:
      return {
        ...state,
        followingCategories: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case FETCH_FOLLOWING_CATEGORIES_FAILURE:
      return {
        ...state,
        followingCategories: {
          data: {},
          loading: true,
          error: action.error,
        }
      };
    case FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_START:
      return {
        ...state,
        followingCategoriesLiveVideos: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        followingCategoriesLiveVideos: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        followingCategoriesLiveVideos: {
          data: {},
          loading: true,
          error: action.error,
        }
      };
    case FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_START:
      return {
        ...state,
        exploreCategoriesLiveVideos: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        exploreCategoriesLiveVideos: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        exploreCategoriesLiveVideos: {
          data: {},
          loading: false,
          error: true,
        }
      };
    case FETCH_RECENT_CATEGORIES_START:
      return {
        ...state,
        recentCategories: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case FETCH_RECENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        recentCategories: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case FETCH_RECENT_CATEGORIES_FAILURE:
      return {
        ...state,
        recentCategories: {
          data: {},
          loading: true,
          error: action.error,
        }
      };
    case FETCH_CATEGORY_VIDEOS_START:
      return {
        ...state,
        categoryVideos: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case FETCH_CATEGORY_VIDEOS_SUCCESS:
      return {
        ...state,
        categoryVideos: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case FETCH_CATEGORY_VIDEOS_FAILURE:
      return {
        ...state,
        categoryVideos: {
          data: {},
          loading: true,
          error: action.error,
        }
      };
    case FETCH_SUB_CATEGORY_VIDEOS_START:
      return {
        ...state,
        subCategoryVideos: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case FETCH_SUB_CATEGORY_VIDEOS_SUCCESS:
      return {
        ...state,
        subCategoryVideos: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case FETCH_SUB_CATEGORY_VIDEOS_FAILURE:
      return {
        ...state,
        subCategoryVideos: {
          data: {},
          loading: true,
          error: action.error,
        }
      };
    case LIVE_VIDEO_BOOKMARK_SAVE_START:
      return {
        ...state,
        liveBookmarkSave: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS:
      return {
        ...state,
        liveBookmarkSave: {
          data: action.data,
          loading: true,
          error: false,
        },
        ongoingLiveVideos: {
          ...state.ongoingLiveVideos,
          data: {
            ...state.ongoingLiveVideos.data,
            live_videos: state.ongoingLiveVideos.data.live_videos.map((video) =>
              video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
            )
          }
        },
        followingCategoriesLiveVideos: {
          ...state.followingCategoriesLiveVideos,
          data: {
            ...state.followingCategoriesLiveVideos.data,
            category_live_videos: state.followingCategoriesLiveVideos.data.category_live_videos.map(category =>
              category.category_id === action.data.live_video.category_id ? {
                ...category,
                live_videos: category.live_videos.map(video =>
                  video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
                )
              } : category
            )
          }
        },
        exploreCategoriesLiveVideos: {
          ...state.exploreCategoriesLiveVideos,
          data: {
            ...state.exploreCategoriesLiveVideos.data,
            category_live_videos: state.exploreCategoriesLiveVideos.data.category_live_videos.map(category =>
              category.category_id === action.data.live_video.category_id ? {
                ...category,
                live_videos: category.live_videos.map(video =>
                  video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
                )
              } : category
            )
          }
        },
        categoryVideos: {
          ...state.categoryVideos,
          data: {
            ...state.categoryVideos.data,
            featured_videos: state.categoryVideos.data.featured_videos.map((video) =>
              video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
            ),
            live_videos: state.categoryVideos.data.live_videos.map((video) =>
              video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
            ),
          }
        },
        subCategoryVideos: {
          ...state.subCategoryVideos,
          data: {
            ...state.subCategoryVideos.data,
            featured_videos: state.subCategoryVideos.data.featured_videos.map((video) =>
              video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
            ),
            live_videos: state.subCategoryVideos.data.live_videos.map((video) =>
              video.live_video_id === action.data.live_video.live_video_id ? action.data.live_video : video
            ),
          }
        },
        bookmarkedVideos: {
          ...state.bookmarkedVideos,
          data: {
            live_video_bookmarks: state.bookmarkedVideos.data.live_video_bookmarks.filter((video) =>
              video.live_video_id !== action.data.live_video.live_video_id
            )
          },
        }
      };
    case LIVE_VIDEO_BOOKMARK_SAVE_FAILURE:
      return {
        ...state,
        liveBookmarkSave: {
          data: {},
          loading: true,
          error: action.error,
        }
      };
    case RESET_ALL_VIDEO_LIST:
      return {
        ...state,
        ongoingLiveVideos: {
          ...state.ongoingLiveVideos,
          data: {
            live_videos: [],
          }
        },
        followingCategoriesLiveVideos: {
          ...state.followingCategoriesLiveVideos,
          data: {
            category_live_videos: [],
          }
        },
        exploreCategoriesLiveVideos: {
          ...state.exploreCategoriesLiveVideos,
          data: {
            category_live_videos: [],
          }
        },
        categoryVideos: {
          ...state.categoryVideos,
          data: {
            featured_videos: [],
            live_videos: [],
          }
        },
        subCategoryVideos: {
          ...state.subCategoryVideos,
          data: {
            featured_videos: [],
            live_videos: [],
          }
        },
        bookmarkedVideos: {
          ...state.bookmarkedVideos,
          data: {
            live_video_bookmarks: []
          },
        }
      };
    case FETCH_BOOKMARKED_VIDEOS_START:
      return {
        ...state,
        bookmarkedVideos: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case FETCH_BOOKMARKED_VIDEOS_SUCCESS:
      return {
        ...state,
        bookmarkedVideos: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case FETCH_BOOKMARKED_VIDEOS_FAILURE:
      return {
        ...state,
        bookmarkedVideos: {
          data: {},
          loading: true,
          error: action.error,
        }
      }
    default:
      return state;
  }
};

export default HomeReducer;
