import React, { useState, useEffect } from "react";
import LivePaymentModal from "./LivePaymentModal";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { Media, Image, Tab, Row, Col, Nav, Form, Button} from "react-bootstrap";

const LiveProducts = (props) => {

    const [paymentModal, setPaymentModal] = useState(false);

    const closePaymentModal = () => {
        setPaymentModal(false);
    };

    return (
        <>
            <div className="video-product-list-card">
                <div className="video-product-list-left-sec">
                    <h4>{props.product.name}</h4>
                    <h6>{props.product.category_name}</h6>
                    <h4 className="sold-color">{props.product.price_formatted}</h4>
                    {/* <p>Set Pre-bid</p> */}
                </div>
                {props.liveVideo.is_streaming == 1 ?
                <div className="video-product-list-right-sec">
                    {props.liveVideo.user_id != localStorage.getItem("userId") ? 
                    <Button
                        type="button"
                        onClick={() => 
                            setPaymentModal(true)                                                    
                        }
                    >  
                      {t("buy_now")}
                    </Button>
                    : ''}
                </div>
                : ''}
            </div>
            <LivePaymentModal
                paymentModal={paymentModal}
                closePaymentModal={closePaymentModal}
                product={props.product}
                liveVideo={props.liveVideo}
            />
        </>
    );
};

const mapStateToPros = (state) => ({
    
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}
  
export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(LiveProducts));
  