import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BookmarkNav from "./BookmarkNav";
import { connect } from "react-redux";
import { fetchBookmarkedVideosStart } from "../../store/actions/HomeAction";
import BookmarkNoDataFound from "../NoDataFound/BookmarkNoDataFound";
import BookmarkLoader from "../Loader/BookmarkLoader";
import useInfiniteScroll from "../helper/useInfiniteScroll";
import { translate, t } from "react-multi-lang";
import SingleVideoCard from "../Whynot/SingleVideoCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const BookmarksIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchBookmarkedVideosStart());
  }, []);

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchBookMarkData);

  const [noMoreData, setNoMoreData] = useState(false);

  function fetchBookMarkData() {
    setTimeout(() => {
      if (props.bookmarkedVideos.length !== 0) {
        props.dispatch(fetchBookmarkedVideosStart());
        setIsFetching(false);
      } else {
        setNoMoreData(true);
      }
    }, 3000);
  }
  return (
    <>
      <Container className="mb-5">
        <h3 className="mt-5 mb-5">{t("all_bookmarks")}</h3>
        <div className="new-category-card-sec">
          <div className="category-total-card">
            {props.bookmarkedVideos.loading ? (
              // <BookmarkLoader />
              [...Array(6)].map((e, i) => (
                <a key={i}>
                  <div className="new-category-card">
                    <div className="new-card-image">
                      <div className="thumbnail-card">
                        <Skeleton className="card-image-loader" />
                      </div>
                      <div className="category-profile-content">
                        <Skeleton className="card-user-image-loader" />
                        <h5>
                          <Skeleton />
                        </h5>
                      </div>
                      <h4>
                        <Skeleton height={30} />
                      </h4>
                    </div>
                    <div className="category-card-link">
                      <Skeleton />
                    </div>
                  </div>
                </a>
              ))
            ) : props.bookmarkedVideos.data.live_video_bookmarks.length >
              0 ? (
              props.bookmarkedVideos.data.live_video_bookmarks.map(
                (live_video_bookmark, i) => (
                  <SingleVideoCard live={live_video_bookmark} key={i} />
                ))
            ) : (
              <BookmarkNoDataFound />
            )}
          </div>
          {noMoreData !== true ? (
            <>{isFetching && "Fetching more list items..."}</>
          ) : (
            t("no_more_data")
          )}
        </div>
      </Container>
    </>
  );
};

const mapStateToPros = (state) => ({
  bookmarkedVideos: state.home.bookmarkedVideos,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(BookmarksIndex));
