import {
    LIVE_VIDEO_CALL_BROADCAST_START,
    LIVE_VIDEO_CALL_BROADCAST_SUCCESS,
    LIVE_VIDEO_CALL_BROADCAST_FAILURE,
    FETCH_LIVE_VIDEOS_START,
    FETCH_LIVE_VIDEOS_SUCCESS,
    FETCH_LIVE_VIDEOS_FAILURE,
    FETCH_LIVE_VIDEOS_HISTORY_START,
    FETCH_LIVE_VIDEOS_HISTORY_SUCCESS,
    FETCH_LIVE_VIDEOS_HISTORY_FAILURE,
    JOIN_LIVE_VIDEOS_START,
    JOIN_LIVE_VIDEOS_SUCCESS,
    JOIN_LIVE_VIDEOS_FAILURE,
    FETCH_SINGLE_LIVE_VIDEOS_START,
    FETCH_SINGLE_LIVE_VIDEOS_SUCCESS,
    FETCH_SINGLE_LIVE_VIDEOS_FAILURE,
    LIVE_VIDEOS_PAYMENT_BY_STRIPE_START,
    LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS,
    LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE,
    LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START,
    LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS,
    LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE,
    LIVE_VIDEOS_VIEWER_UPDATE_START,
    LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS,
    LIVE_VIDEOS_VIEWER_UPDATE_FAILURE,
    LIVE_VIDEOS_END_START,
    LIVE_VIDEOS_END_SUCCESS,
    LIVE_VIDEOS_END_FAILURE,
    LIVE_STREAMINGS_ONGOING_START,
    LIVE_STREAMINGS_ONGOING_SUCCESS,
    LIVE_STREAMINGS_ONGOING_FAILURE,
    UPCOMMING_LIVE_STREAMINGS_START,
    UPCOMMING_LIVE_STREAMINGS_SUCCESS,
    UPCOMMING_LIVE_STREAMINGS_FAILURE,
    FETCH_LIVE_VIDEO_MESSAGE_START,
    FETCH_LIVE_VIDEO_MESSAGE_SUCCESS,
    FETCH_LIVE_VIDEO_MESSAGE_FAILURE,
    ADD_LIVE_VIDEO_MESSAGE_CONTENT,
    FETCH_LIVE_VIDEOS_PRODUCTS_START,
    FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS,
    FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE,
    FETCH_LIVE_VIDEO_ORDERS_START,
    FETCH_LIVE_VIDEO_ORDERS_SUCCESS,
    FETCH_LIVE_VIDEO_ORDERS_FAILURE,
    FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START,
    FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_SUCCESS,
    FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_FAILURE,
    LIVE_VIDEOS_START_CALL_START,
    LIVE_VIDEOS_START_CALL_SUCCESS,
    LIVE_VIDEOS_START_CALL_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
    saveLiveVideo: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    joinliveVideo: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        buttonDisable: false,
        loadingButtonContent: null,
    },
    singleLiveVideo: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    liveVideos: {
        data: {
            videos: [],
        },
        responseData: {},
        loading: true,
        error: false,
        skip: 0,
        length: 0,
    },
    liveVideosHistory: {
        data: {
            videos: [],
        },
        loading: true,
        error: false,
        skip: 0,
        length: 0,
    },
    liveVideosScheduledOwner: {
        data: {
            videos: [],
        },
        loading: true,
        error: false,
        skip: 0,
        length: 0,
    },
    liveStripe: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    livePaypal: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    liveViewerUpdate: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    liveEnd: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    liveStart: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    liveStreamingsOngoing: {
        data: {},
        loading: true,
        error: false,
    },
    upcommingLiveStreamings: {
        data: {},
        loading: true,
        error: false,
    },
    liveVideoChat: {
        data: {
            messages: [],
        },
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    liveVideosProducts: {
        data: {
            products: [],
        },
        loading: true,
        error: false,
        inputData: {},
        skip: 0,
        length: 0,
        productData: {},
        loadMore: false,
    },
    liveVideoOrders: {
        data: {
            orders: [],
        },
        loading: true,
        error: false,
        inputData: {},
        skip: 0,
        length: 0,
        orderData: {},
        loadMore: false,
    },
};

const LiveVideoReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIVE_VIDEO_CALL_BROADCAST_START:
            return {
                ...state,
                saveLiveVideo: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case LIVE_VIDEO_CALL_BROADCAST_SUCCESS:
            return {
                ...state,
                saveLiveVideo: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case LIVE_VIDEO_CALL_BROADCAST_FAILURE:
            return {
                ...state,
                saveLiveVideo: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_LIVE_VIDEOS_START:
            return {
                ...state,
                liveVideos: {
                    data: {
                        videos: [...state.liveVideos.data.videos],
                    },
                    loading: true,
                    error: false,
                    skip: state.liveVideos.skip,
                    length: state.liveVideos.length,
                },
            };
        case FETCH_LIVE_VIDEOS_SUCCESS:
            return {
                ...state,
                liveVideos: {
                    data: action.data,
                    data: {
                        videos: [...state.liveVideos.data.videos, ...action.data.live_videos],
                    },
                    loading: false,
                    error: false,
                    skip: action.data.live_videos.length + state.liveVideos.skip,
                    length: action.data.live_videos.length,
                },
            };
        case FETCH_LIVE_VIDEOS_FAILURE:
            return {
                ...state,
                liveVideos: {
                    data: {},
                    loading: false,
                    error: action.error,
                    skip: state.liveVideos.skip,
                    length: state.liveVideos.length,
                },
            };
        case FETCH_LIVE_VIDEOS_HISTORY_START:
            return {
                ...state,
                liveVideosHistory: {
                    data: {
                        videos: [...state.liveVideosHistory.data.videos],
                    },
                    responseData: {},
                    loading: true,
                    error: false,
                    skip: state.liveVideosHistory.skip,
                    length: state.liveVideosHistory.length,
                },
            };
        case FETCH_LIVE_VIDEOS_HISTORY_SUCCESS:
            return {
                ...state,
                liveVideosHistory: {
                    responseData: action.data,
                    data: {
                        videos: [...state.liveVideosHistory.data.videos, ...action.data.live_videos],
                    },
                    loading: false,
                    error: false,
                    skip: action.data.live_videos.length + state.liveVideosHistory.skip,
                    length: action.data.live_videos.length,
                },
            };
        case FETCH_LIVE_VIDEOS_HISTORY_FAILURE:
            return {
                ...state,
                liveVideosHistory: {
                    data: {},
                    responseData: {},
                    loading: false,
                    error: action.error,
                    skip: state.liveVideosHistory.skip,
                    length: state.liveVideosHistory.length,
                },
            };
        case FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START:
            return {
                ...state,
                liveVideosScheduledOwner: {
                    data: {
                        videos: [...state.liveVideosScheduledOwner.data.videos],
                    },
                    responseData: {},
                    loading: true,
                    error: false,
                    skip: state.liveVideosScheduledOwner.skip,
                    length: state.liveVideosScheduledOwner.length,
                }
            };
        case FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_SUCCESS:
            return {
                ...state,
                liveVideosScheduledOwner: {
                    responseData: action.data,
                    data: {
                        videos: [...state.liveVideosScheduledOwner.data.videos, ...action.data.live_video_upcomings],
                    },
                    loading: false,
                    error: false,
                    skip: action.data.live_video_upcomings.length + state.liveVideosScheduledOwner.skip,
                    length: action.data.live_video_upcomings.length,
                }
            };
        case FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START:
            return {
                ...state,
                liveVideosScheduledOwner: {
                    data: {},
                    responseData: {},
                    loading: false,
                    error: action.error,
                    skip: state.liveVideosScheduledOwner.skip,
                    length: state.liveVideosScheduledOwner.length,
                }
            };

        case FETCH_SINGLE_LIVE_VIDEOS_START:
            return {
                ...state,
                singleLiveVideo: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                },
            };
        case FETCH_SINGLE_LIVE_VIDEOS_SUCCESS:
            return {
                ...state,
                singleLiveVideo: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_SINGLE_LIVE_VIDEOS_FAILURE:
            return {
                ...state,
                singleLiveVideo: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case JOIN_LIVE_VIDEOS_START:
            return {
                ...state,
                joinliveVideo: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    data: {},
                    buttonDisable: true,
                    loadingButtonContent: "Loading... Please wait",
                },
            };
        case JOIN_LIVE_VIDEOS_SUCCESS:
            return {
                ...state,
                joinliveVideo: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case JOIN_LIVE_VIDEOS_FAILURE:
            return {
                ...state,
                joinliveVideo: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case LIVE_VIDEOS_PAYMENT_BY_STRIPE_START:
            return {
                ...state,
                liveStripe: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS:
            return {
                ...state,
                liveStripe: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE:
            return {
                ...state,
                liveStripe: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START:
            return {
                ...state,
                livePaypal: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS:
            return {
                ...state,
                livePaypal: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE:
            return {
                ...state,
                livePaypal: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case LIVE_VIDEOS_VIEWER_UPDATE_START:
            return {
                ...state,
                liveViewerUpdate: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS:
            return {
                ...state,
                liveViewerUpdate: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case LIVE_VIDEOS_VIEWER_UPDATE_FAILURE:
            return {
                ...state,
                liveViewerUpdate: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case LIVE_VIDEOS_END_START:
            return {
                ...state,
                liveEnd: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case LIVE_VIDEOS_END_SUCCESS:
            return {
                ...state,
                liveEnd: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case LIVE_VIDEOS_END_FAILURE:
            return {
                ...state,
                liveEnd: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case LIVE_STREAMINGS_ONGOING_START:
            return {
                ...state,
                liveStreamingsOngoing: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case LIVE_STREAMINGS_ONGOING_SUCCESS:
            return {
                ...state,
                liveStreamingsOngoing: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case LIVE_STREAMINGS_ONGOING_FAILURE:
            return {
                ...state,
                liveStreamingsOngoing: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case UPCOMMING_LIVE_STREAMINGS_START:
            return {
                ...state,
                upcommingLiveStreamings: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case UPCOMMING_LIVE_STREAMINGS_SUCCESS:
            return {
                ...state,
                upcommingLiveStreamings: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case UPCOMMING_LIVE_STREAMINGS_FAILURE:
            return {
                ...state,
                upcommingLiveStreamings: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case FETCH_LIVE_VIDEO_MESSAGE_START:
            return {
                ...state,
                liveVideoChat: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                },
            };
        case FETCH_LIVE_VIDEO_MESSAGE_SUCCESS:
            return {
                ...state,
                liveVideoChat: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_LIVE_VIDEO_MESSAGE_FAILURE:
            return {
                ...state,
                liveVideoChat: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };

        case ADD_LIVE_VIDEO_MESSAGE_CONTENT:
            return {
                ...state,
                liveVideoChat: {
                    data: {
                        messages: [...state.liveVideoChat.data.messages, ...action.data],
                    },
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_LIVE_VIDEOS_PRODUCTS_START:
            return {
                ...state,
                liveVideosProducts: {
                    inputData: action.data,
                    data: {
                        products: action.data.loadMore === "loadMore" ? [...state.liveVideosProducts.data.products] : [],
                    },
                    loadMore: action.data.loadMore === "loadMore" ? true : false,
                    loading: true,
                    error: false,
                    productData: {},
                    skip: action.data.loadMore === "loadMore" ? state.liveVideosProducts.skip : 0,
                    length: action.data.loadMore === "loadMore" ? state.liveVideosProducts.length : 0,
                },
            };
        case FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS:
            return {
                ...state,
                liveVideosProducts: {
                    inputData: {},
                    data: {
                        products: state.liveVideosProducts.loadMore ? [...state.liveVideosProducts.data.products, ...action.data.live_video_products] : [...action.data.live_video_products],
                    },
                    loadMore: state.liveVideosProducts.loadMore,
                    loading: false,
                    error: false,
                    productData: action.data,
                    skip: action.data.live_video_products.length + state.liveVideosProducts.skip,
                    length: action.data.live_video_products.length,
                },
            };
        case FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE:
            return {
                ...state,
                liveVideosProducts: {
                    data: {},
                    loading: false,
                    inputData: {},
                    error: action.error,
                    skip: state.liveVideosProducts.skip,
                    length: state.liveVideosProducts.length,
                },
            };
        case FETCH_LIVE_VIDEO_ORDERS_START:
            return {
                ...state,
                liveVideoOrders: {
                    inputData: action.data,
                    data: {
                        orders: [],
                    },
                    loadMore: false,
                    loading: true,
                    error: false,
                    orderData: {},
                    skip: 0,
                    length: 0,
                },
            };
        case FETCH_LIVE_VIDEO_ORDERS_SUCCESS:
            return {
                ...state,
                liveVideoOrders: {
                    inputData: {},
                    data: {
                        products: [...action.data.orders],
                    },
                    loadMore: state.liveVideoOrders.loadMore,
                    loading: false,
                    error: false,
                    orderData: action.data,
                    skip: action.data.orders.length + state.liveVideoOrders.skip,
                    length: action.data.orders.length,
                },
            };
        case FETCH_LIVE_VIDEO_ORDERS_FAILURE:
            return {
                ...state,
                liveVideoOrders: {
                    data: {},
                    loading: false,
                    inputData: {},
                    error: action.error,
                    skip: state.liveVideoOrders.skip,
                    length: state.liveVideoOrders.length,
                },
            };
        case LIVE_VIDEOS_START_CALL_START:
            return {
                ...state,
                liveStart: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case LIVE_VIDEOS_START_CALL_SUCCESS:
            return {
                ...state,
                liveStart: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case LIVE_VIDEOS_START_CALL_FAILURE:
            return {
                ...state,
                liveStart: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        default:
            return state;
    }
};

export default LiveVideoReducer;
