import React, { useState, useEffect } from "react";
import {
    NavDropdown,
    Nav,
    Navbar,
    Container,
    Card,
    Image,
} from "react-bootstrap";
import {
    fetchCategoryVideosStart,
    fetchSubCategoryVideosStart,
    resetAllVideoList,
} from "../../store/actions/HomeAction";
import {
    fetchCategoriesListStart,
    followCategoriesStart,
    fetchSubCategoriesStart,
} from "../../store/actions/LookUpAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import SingleVideoCard from "./SingleVideoCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { translate, t } from "react-multi-lang";

const NewCategoryCard = (props) => {
    const [activeId, setActiveId] = useState(null);
    const [videos, setVideos] = useState({ loading: true });
    const [followStatus, setFollowStatus] = useState(false);

    useEffect(() => {
        console.log(props.selectedCategory);
        if (props.selectedCategory.categoryId !== null) {
            //Category API Call
            props.dispatch(
                fetchCategoryVideosStart({ category_id: props.selectedCategory.categoryId })
            );
            props.dispatch(fetchSubCategoriesStart({ category_unique_id: props.selectedCategory.categoryId }));
        } else if (props.selectedCategory.subCategoryId !== null) {
            //Sub Category API Call
            props.dispatch(
                fetchSubCategoryVideosStart({
                    sub_category_id: props.selectedCategory.subCategoryId,
                })
            );
        }
        return () => {
            props.dispatch(resetAllVideoList())
        }
    }, [props.selectedCategory]);

    useEffect(() => {
        if (props.selectedCategory.categoryId !== null) {
            setVideos(props.categoryVideos);
            setFollowStatus(props.categoryVideos.data.is_following);
        } else if (props.selectedCategory.subCategoryId !== null) {
            setVideos(props.subCategoryVideos);
            setFollowStatus(props.subCategoryVideos.data.is_following);
        }
    }, [props.categoryVideos, props.subCategoryVideos]);

    useEffect(() => {
        if (!props.followCategories.loading && !props.followCategories.error) {
            setFollowStatus(!followStatus);
        }
    }, [props.followCategories]);

    function toggleActive(id) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    const [isActive, setActive] = useState(true);

    const toggleClass = () => {
        setActive(!isActive);
    };

    const followUnfollow = (e) => {
        e.preventDefault();
        if (localStorage.getItem('userId') && localStorage.getItem('accessToken')) {
            if (props.selectedCategory.categoryId !== null) {
                props.dispatch(followCategoriesStart({ category_id: props.selectedCategory.categoryId }));
            } else if (props.selectedCategory.subCategoryId !== null) {
                props.dispatch(
                    followCategoriesStart({ sub_category_id: props.selectedCategory.subCategoryId })
                );
            }
        } else {
            const notificationMessage = getErrorNotificationMessage("Please Login to Follow");
            props.dispatch(createNotification(notificationMessage));
            window.location.assign("/login");
        }
    };

    return (

        <>
            <div className="category-card-head">
                {!videos.loading ? (
                    <>
                        <h2>{videos.data.name}</h2>
                        <a
                            href="#"
                            className={`${followStatus ? "active" : ""}`}
                            onClick={(e) => followUnfollow(e)}
                        >
                            {followStatus ? "Following" : "Follow"}
                        </a>
                    </>
                ) : null}
            </div>
            {props.selectedCategory.categoryId !== null &&
                !props.subCategories.loading &&
                props.subCategories.data.sub_categories &&
                props.subCategories.data.sub_categories.length > 0 &&

                <div className="new-card-head pt-8">
                    <h3>Sub Categories</h3>
                    <div className="category-tab-card">
                        <ul>
                            {props.subCategories.data.sub_categories.map((subCategory, i) =>
                                <li key={i} className="category-thumb">
                                    <Link to={`/?subCategory=${subCategory.unique_id}`}>
                                        {subCategory.name}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            }
            {!videos.loading ?
                (videos.data.featured_videos && 
                    videos.data.live_videos && (
                        <>
                            {videos.data.featured_videos.length > 0 && (
                                <div className="new-category-card-sec">
                                    <div className="new-card-title">
                                        <h4 className="mtb-1">{t("featured_videos")}</h4>
                                    </div>
                                    <div className="category-total-card">
                                        {videos.data.featured_videos.map((video, i) => (
                                            <SingleVideoCard live={video} key={i} />
                                        ))}
                                    </div>
                                </div>
                            )}
                            {videos.data.live_videos.length > 0 && (
                                <div className="new-category-card-sec">
                                    <div className="new-card-title">
                                        <h4 className="mtb-1">
                                            {videos.data.live_videos.length} {t("shows")}
                                        </h4>
                                    </div>
                                    <div className="category-total-card">
                                        {videos.data.live_videos.map((video, i) => (
                                            <SingleVideoCard live={video} key={i} />
                                        ))}
                                    </div>
                                </div>
                            )}
                            {videos.data.featured_videos.length === 0 &&
                                videos.data.live_videos.length === 0 && (
                                    <div className="text-center pt-8 h4">{t("no_data_found")}</div>
                                )}
                        </>
                    )
                ) : <>
                    <div className="new-card-title pt-8">
                        <h4>
                            <Skeleton width={300} height={40} />
                        </h4>
                    </div>
                    <div className="new-category-list-card">
                        {[...Array(5)].map((e, i) => (
                            <a key={i}>
                                <div className="new-category-card">
                                    <div className="new-card-image">
                                        <div className="thumbnail-card">
                                            <Skeleton className="card-image-loader" />
                                        </div>
                                        <div className="category-profile-content">
                                            <Skeleton className="card-user-image-loader" />
                                            <h5>
                                                <Skeleton />
                                            </h5>
                                        </div>
                                        <h4>
                                            <Skeleton height={30} />
                                        </h4>
                                    </div>
                                    <div className="category-card-link">
                                        <Skeleton />
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </>
            }
        </>
    );
};

const mapStateToPros = (state) => ({
    categoriesList: state.lookUp.categoriesList,
    categoryVideos: state.home.categoryVideos,
    subCategoryVideos: state.home.subCategoryVideos,
    followCategories: state.lookUp.followCategories,
    subCategories: state.lookUp.subCategories,
});

const mapDispatchToProps = (dispatch) => {
    return { dispatch };
};

export default connect(mapStateToPros, mapDispatchToProps)(translate(NewCategoryCard));
