import {
  SEARCH_USER_START,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  FETCH_LISTS_DETAILS_START,
  FETCH_LISTS_DETAILS_SUCCESS,
  FETCH_LISTS_DETAILS_FAILURE,
  FETCH_TRENDING_USERS_START,
  FETCH_TRENDING_USERS_SUCCESS,
  FETCH_TRENDING_USERS_FAILURE,
  FETCH_ONGOING_LIVE_VIDEOS_START,
  FETCH_ONGOING_LIVE_VIDEOS_SUCCESS,
  FETCH_ONGOING_LIVE_VIDEOS_FAILURE,
  FETCH_FOLLOWING_CATEGORIES_START,
  FETCH_FOLLOWING_CATEGORIES_FAILURE,
  FETCH_FOLLOWING_CATEGORIES_SUCCESS,
  FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_START,
  FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_SUCCESS,
  FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_FAILURE,
  FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_START,
  FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_SUCCESS,
  FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_FAILURE,
  FETCH_RECENT_CATEGORIES_START,
  FETCH_RECENT_CATEGORIES_SUCCESS,
  FETCH_RECENT_CATEGORIES_FAILURE,
  FETCH_CATEGORY_VIDEOS_START,
  FETCH_CATEGORY_VIDEOS_SUCCESS,
  FETCH_CATEGORY_VIDEOS_FAILURE,
  FETCH_SUB_CATEGORY_VIDEOS_START,
  FETCH_SUB_CATEGORY_VIDEOS_SUCCESS,
  FETCH_SUB_CATEGORY_VIDEOS_FAILURE,
  LIVE_VIDEO_BOOKMARK_SAVE_START,
  LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS,
  LIVE_VIDEO_BOOKMARK_SAVE_FAILURE,
  RESET_ALL_VIDEO_LIST,
  FETCH_BOOKMARKED_VIDEOS_START,
  FETCH_BOOKMARKED_VIDEOS_SUCCESS,
  FETCH_BOOKMARKED_VIDEOS_FAILURE,
} from "./ActionConstant";

export function searchUserStart(data) {
  return {
    type: SEARCH_USER_START,
    data,
  };
}

export function searchUserSuccess(data) {
  return {
    type: SEARCH_USER_SUCCESS,
    data,
  };
}

export function searchUserFailure(error) {
  return {
    type: SEARCH_USER_FAILURE,
    error,
  };
}

export function fetchListsDetailsStart(data) {
  return {
    type: FETCH_LISTS_DETAILS_START,
    data,
  };
}

export function fetchListsDetailsSuccess(data) {
  return {
    type: FETCH_LISTS_DETAILS_SUCCESS,
    data,
  };
}

export function fetchListsDetailsFailure(error) {
  return {
    type: FETCH_LISTS_DETAILS_FAILURE,
    error,
  };
}

export function fetchTrendingUsersStart(data) {
  return {
    type: FETCH_TRENDING_USERS_START,
    data,
  };
}

export function fetchTrendingUsersSuccess(data) {
  return {
    type: FETCH_TRENDING_USERS_SUCCESS,
    data,
  };
}

export function fetchTrendingUsersFailure(error) {
  return {
    type: FETCH_TRENDING_USERS_FAILURE,
    error,
  };
}

export function fetchOngoingLiveVideosStart(data) {
  return {
    type: FETCH_ONGOING_LIVE_VIDEOS_START,
    data,
  };
}

export function fetchOngoingLiveVideosSuccess(data) {
  return {
    type: FETCH_ONGOING_LIVE_VIDEOS_SUCCESS,
    data,
  };
}

export function fetchOngoingLiveVideosFailure(error) {
  return {
    type: FETCH_ONGOING_LIVE_VIDEOS_FAILURE,
    error,
  };
}

export function fetchFollowingCategoriesStart(data) {
  return {
    type: FETCH_FOLLOWING_CATEGORIES_START,
    data,
  };
}

export function fetchFollowingCategoriesSuccess(data) {
  return {
    type: FETCH_FOLLOWING_CATEGORIES_SUCCESS,
    data,
  };
}

export function fetchFollowingCategoriesFailure(error) {
  return {
    type: FETCH_FOLLOWING_CATEGORIES_FAILURE,
    error,
  };
}

export function fetchFollowingCategoryLiveVideoStart(data) {
  return {
    type: FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_START,
    data,
  };
}

export function fetchFollowingCategoriesLiveVideosSuccess(data) {
  return {
    type: FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_SUCCESS,
    data,
  };
}

export function fetchFollowingCategoriesLiveVideosFailure(error) {
  return {
    type: FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_FAILURE,
    error,
  };
}


export function fetchExploreCategoriesLiveVideoStart(data) {
  return {
    type: FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_START,
    data,
  };
}

export function fetchExploreCategoriesLiveVideoSuccess(data) {
  return {
    type: FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_SUCCESS,
    data,
  };
}

export function fetchExploreCategoriesLiveVideoFailure(error) {
  return {
    type: FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_FAILURE,
    error,
  };
}

export function fetchRecentCategoriesStart(data) {
  return {
    type: FETCH_RECENT_CATEGORIES_START,
    data,
  };
}

export function fetchRecentCategoriesSuccess(data) {
  return {
    type: FETCH_RECENT_CATEGORIES_SUCCESS,
    data,
  };
}

export function fetchRecentCategoriesFailure(error) {
  return {
    type: FETCH_RECENT_CATEGORIES_FAILURE,
    error,
  }
}

export function fetchCategoryVideosStart(data) {
  return {
    type: FETCH_CATEGORY_VIDEOS_START,
    data,
  };
}

export function fetchCategoryVideosSuccess(data) {
  return {
    type: FETCH_CATEGORY_VIDEOS_SUCCESS,
    data,
  };
}

export function fetchCategoryVideosFailure(error) {
  return {
    type: FETCH_CATEGORY_VIDEOS_FAILURE,
    error,
  }
}

export function fetchSubCategoryVideosStart(data) {
  return {
    type: FETCH_SUB_CATEGORY_VIDEOS_START,
    data,
  }
}

export function fetchSubCategoryVideosSuccess(data) {
  return {
    type: FETCH_SUB_CATEGORY_VIDEOS_SUCCESS,
    data,
  }
}

export function fetchSubCategoryVideosFailure(error) {
  return {
    type: FETCH_SUB_CATEGORY_VIDEOS_FAILURE,
    error,
  }
}

export function liveVideoBookmarkSaveStart(data) {
  return {
    type: LIVE_VIDEO_BOOKMARK_SAVE_START,
    data,
  }
}

export function liveVideoBookmarkSaveSuccess(data) {
  return {
    type: LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS,
    data,
  }
}

export function liveVideoBookmarkSaveFailure(error) {
  return {
    type: LIVE_VIDEO_BOOKMARK_SAVE_FAILURE,
    error,
  }
}

export function resetAllVideoList(data) {
  return {
    type: RESET_ALL_VIDEO_LIST,
    data,
  }
}

export function fetchBookmarkedVideosStart(data) {
  return {
      type: FETCH_BOOKMARKED_VIDEOS_START,
      data,
  }
}

export function fetchBookmarkedVideosSuccess(data) {
  return {
      type: FETCH_BOOKMARKED_VIDEOS_SUCCESS,
      data,
  }
}

export function fetchBookmarkedVideosFailure(error) {
  return {
      type: FETCH_BOOKMARKED_VIDEOS_FAILURE,
      error,
  }
}